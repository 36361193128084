import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import { metricsApi, getCurrencies } from '../../Api/apicalls';
import { calculateAmount } from '../../utils';
import { Grid, Paper } from '@mui/material';
import { CurrencyLogo } from '../../Components/CurrencyLogo';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const footer = (tooltipItems) => {
	let sum = 0;

	tooltipItems.forEach(function (tooltipItem) {
		sum += tooltipItem.parsed.y;
	});
	return 'Sum: ' + sum;
};

const getOptions = (title, customTooltip = false) => {
	const optionsaaa = {
		responsive: true,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		stacked: false,
		plugins: {
			title: {
				display: true,
				text: title,
			},
		},
		scales: {
			y: {
				type: 'linear',
				display: true,
				position: 'left',
			},
			y1: {
				type: 'linear',
				display: true,
				position: 'right',
				grid: {
					drawOnChartArea: false,
				},
			},
		},
	};
	if (customTooltip) {
		optionsaaa.plugins.tooltip = {
			callbacks: {
				footer: footer,
			},
		};
	}
	return optionsaaa;
};

const colors = [
	'rgb(96,96,96,1)',
	'rgba(81, 175, 149, 1)',
	'rgba(39, 117, 202, 1)',
	'rgba(245, 173, 55, 1)',
	'rgba(247,147,26,1)',
	'rgba(130, 71, 229, 1)',
];
const borderColors = [
	'rgb(96,96,96,0.4)',
	'rgba(81, 175, 149, 0.4)',
	'rgba(39, 117, 202, 0.4)',
	'rgba(245, 173, 55, 0.4)',
	'rgba(247,147,26,0.4)',
	'rgba(130, 71, 229, 0.4)',
];

const filterData = (data, days) => {
	const today = new Date();
	const startDate = new Date(today);
	startDate.setDate(today.getDate() - days);
	let filteredData = {};
	Object.keys(data).forEach((key) => {
		const date = new Date(key);
		if (date >= startDate) {
			filteredData[key] = data[key];
		}
	});
	return filteredData;
};

export function Charts() {
	const [filterParam, setFilterParam] = useState(100000);
	const searchParams = new URLSearchParams(document.location.search);
	const [isLoading, setIsLoading] = useState(true);
	const [res, setRes] = useState({});
	const [currencies, setCurrencies] = useState({});
	const [exchangeRates, setExchangeRates] = useState({});
	const [nativeCurrency, setNativeCurrency] = useState({});

	const amtUsd = (amt, address) => {
		let tokenValue = calculateAmount(amt, address, currencies);
		return tokenValue * exchangeRates[currencies[address].Abbreviation].toFixed(2);
	};

	const fetchData = async () => {
		try {
			let result = await metricsApi(searchParams.get('apiKey'));
			let res = await getCurrencies();
			let d = {};
			let exchangeRatePromise = [];
			res.data.message.Currencies.forEach((e) => {
				d[e.ContractAddress] = e;
				exchangeRatePromise.push(
					fetch(`https://min-api.cryptocompare.com/data/price?fsym=${e.Abbreviation}&tsyms=USD`)
				);
				if (e.Native) {
					setNativeCurrency(e);
				}
			});
			const xr = {
				ETH: 1900,
				USDT: 1,
				USDC: 1,
				DAI: 1,
				MATIC: 1,
				WBTC: 28357,
			};

			setExchangeRates(xr);
			setCurrencies(d);
			setRes(result.data);

			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, []);

	if (isLoading) {
		return <div className="App">Loading...</div>;
	}

	let totalValueUsd = 0;

	const number_and_value_of_deposits_daily = res.message.number_and_value_of_deposits_daily;
	const number_and_value_of_transfers_daily = res.message.number_and_value_of_transfers_daily;
	const number_of_unique_transactors_daily = res.message.unique_address_transfers_daily;
	const number_of_blocks_daily = res.message.blocks_per_day;

	const number_of_deposits_daily_data = {
		labels: Object.keys(
			filterData(number_and_value_of_deposits_daily[nativeCurrency.ContractAddress], filterParam)
		),
		datasets: Object.keys(number_and_value_of_deposits_daily).map((address, i) => {
			return {
				label: currencies[address].Abbreviation,
				data: Object.values(filterData(number_and_value_of_deposits_daily[address], filterParam)).map(
					(item) => item.Number
				),
				fill: false,
				backgroundColor: colors[i],
				borderColor: borderColors[i],
			};
		}),
	};

	const number_of_transfers_daily_data = {
		labels: Object.keys(
			filterData(number_and_value_of_transfers_daily[nativeCurrency.ContractAddress], filterParam)
		),
		datasets: Object.keys(number_and_value_of_transfers_daily).map((address, i) => {
			return {
				label: currencies[address].Abbreviation,
				data: Object.values(number_and_value_of_transfers_daily[address]).map((item) => item.Number),
				fill: false,
				backgroundColor: colors[i],
				borderColor: borderColors[i],
			};
		}),
	};

	const value_of_deposits_daily_data = {
		labels: Object.keys(
			filterData(number_and_value_of_deposits_daily[nativeCurrency.ContractAddress], filterParam)
		),
		datasets: Object.keys(number_and_value_of_deposits_daily).map((address, i) => {
			return {
				label: currencies[address].Abbreviation,
				data: Object.values(filterData(number_and_value_of_deposits_daily[address], filterParam)).map((item) =>
					amtUsd(item.Value, address)
				),
				fill: false,
				backgroundColor: colors[i],
				borderColor: borderColors[i],
			};
		}),
	};

	const value_of_transfers_daily_data = {
		labels: Object.keys(
			filterData(number_and_value_of_transfers_daily[nativeCurrency.ContractAddress], filterParam)
		),
		datasets: Object.keys(number_and_value_of_transfers_daily).map((address, i) => {
			return {
				label: currencies[address].Abbreviation,
				data: Object.values(filterData(number_and_value_of_transfers_daily[address], filterParam)).map((item) =>
					amtUsd(item.Value, address)
				),
				fill: false,
				backgroundColor: colors[i],
				borderColor: borderColors[i],
			};
		}),
	};
	const number_of_unique_transactors = {
		labels: Object.keys(filterData(number_of_unique_transactors_daily, filterParam)),
		datasets: [
			{
				label: '# number of unique transactors',
				data: Object.values(filterData(number_of_unique_transactors_daily, filterParam)),
				fill: false,
				backgroundColor: 'black',
				borderColor: 'rgba(0,0,0,0.4)',
			},
		],
	};
	const number_of_blocks = {
		labels: Object.keys(filterData(number_of_blocks_daily, filterParam)),
		datasets: [
			{
				label: '# number of blocks',
				data: Object.values(filterData(number_of_blocks_daily, filterParam)),
				fill: false,
				backgroundColor: 'black',
				borderColor: 'rgba(0,0,0,0.4)',
			},
		],
	};

	return (
		<>
			<Grid container spacing={2} sx={{ py: 2 }}>
				<Grid item xs={3}>
					<Paper sx={{ p: 2 }}>User count : {res.message.Counts.user_count}</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper sx={{ p: 2 }}>Subscription count : {res.message.Counts.subscription_count}</Paper>
				</Grid>
				<Grid item xs={6}>
					<Paper sx={{ p: 2 }}>
						Fees Paid on Nume:{' '}
						{calculateAmount(res.message.nume_fees, nativeCurrency.ContractAddress, currencies)}{' '}
						{currencies[nativeCurrency.ContractAddress].Abbreviation} ($
						{amtUsd(res.message.nume_fees, nativeCurrency.ContractAddress)})
					</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper sx={{ p: 2 }}>Deposit count : {res.message.Counts.deposit_count}</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper sx={{ p: 2 }}>Withdrawal count : {res.message.Counts.withdrawal_count}</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper sx={{ p: 2 }}>Transfer count : {res.message.Counts.transfer_count}</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper sx={{ p: 2 }}>
						Total count :{' '}
						{res.message.Counts.transfer_count +
							res.message.Counts.withdrawal_count +
							res.message.Counts.deposit_count}
					</Paper>
				</Grid>
			</Grid>
			<div>
				Total Value Locked :{' '}
				<Grid container spacing={2} sx={{ py: 2 }}>
					{Object.keys(res.message.total_value_locked).map((address) => {
						totalValueUsd += amtUsd(res.message.total_value_locked[address], address);
						return (
							<Grid item xs={4} key={address}>
								<Paper sx={{ p: 2 }}>
									<CurrencyLogo
										token={currencies[address].Abbreviation}
										style={{
											verticalAlign: 'middle',
											marginRight: '8px',
											height: '24px',
											width: '24px',
										}}
									/>
									{calculateAmount(res.message.total_value_locked[address], address, currencies)}{' '}
									{currencies[address].Abbreviation} ($
									{amtUsd(res.message.total_value_locked[address], address)})
								</Paper>
							</Grid>
						);
					})}
					<Grid item xs={4}>
						<Paper sx={{ p: 2 }}>Total Value: {totalValueUsd}</Paper>
					</Grid>
				</Grid>
			</div>
			<h1 style={{ marginTop: '16px', marginBottom: '16px' }}>Charts</h1>
			<ButtonGroup variant="contained" aria-label="outlined primary button group">
				<Button onClick={() => setFilterParam(100000)}>All</Button>
				<Button onClick={() => setFilterParam(180)}>6 months</Button>
				<Button onClick={() => setFilterParam(30)}>1 month</Button>
				<Button onClick={() => setFilterParam(7)}>7 days</Button>
			</ButtonGroup>
			<Line options={getOptions('Number of daily deposits', true)} data={number_of_deposits_daily_data} />;
			<Line options={getOptions('Value of daily deposits', true)} data={value_of_deposits_daily_data} />;
			<Line options={getOptions('Number of daily transfers', true)} data={number_of_transfers_daily_data} />;
			<Line options={getOptions('Value of daily transfers', true)} data={value_of_transfers_daily_data} />;
			<Line
				options={getOptions('Number of unique transacting addresses per day')}
				data={number_of_unique_transactors}
			/>
			<Line options={getOptions('Number of blocks per day')} data={number_of_blocks} />
		</>
	);
}
