import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getWorkflowDetails, getCurrencies } from '../../Api/apicalls';
import {
	Box,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Button,
	Grid,
} from '@mui/material';
import { Loader } from '../../Components/Loader';
import { styled } from '@mui/material/styles';
import { Copy } from '../../Components/Copy';
import { truncateEthAddress, type } from '../../utils';
import { SettlementData, SettlementState } from './Settlement';
import { ValidatorRegistrationData } from './ValidatorRegistration';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#f9fafb',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const Selectedblocks = () => {
	const [blockData, setBlockData] = useState();
	const [currencies, setCurrencies] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const { blockid } = useParams();

	async function handleblocktransactions(blockid) {
		try {
			let res = await getWorkflowDetails(blockid);
			setBlockData(res.data.message);
			let res1 = await getCurrencies();
			let d = {};
			res1.data.message.Currencies.forEach((e) => {
				d[e.ID] = e;
			});
			setCurrencies(d);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}

	useEffect(() => {
		handleblocktransactions(blockid);
		return () => {};
	}, [blockid]);

	if (loading) {
		return <Loader />;
	}

	const BlockchainTransations = () => {
		switch (blockData.BlockchainTxData.TransactionType) {
			case 'settlement':
				return <SettlementData blockData={blockData} currencies={currencies} />;
			case 'validatorRegistration':
				return <ValidatorRegistrationData blockData={blockData} currencies={currencies} />;
			default:
				return <></>;
		}
	};

	const BlockchainStates = () => {
		switch (blockData.BlockchainTxData.TransactionType) {
			case 'settlement':
				return <SettlementState blockData={blockData} />;
			default:
				return <></>;
		}
	};

	return (
		<>
			<Box>
				<Button onClick={() => navigate('/')} variant="outlined" sx={{ textTransform: 'none' }}>
					Dashboard
				</Button>
				<Typography sx={{ fontSize: '36px', fontWeight: 'bold', mt: 1 }}>Block #{blockid}</Typography>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					<Grid item xs={12} md={6}>
						<TableContainer component={Paper} sx={{ mt: 1 }}>
							<Table>
								<TableBody>
									<StyledTableRow hover>
										<TableCell variant="head">Type</TableCell>
										<TableCell>{type(blockData.BlockchainTxData.TransactionType)}</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Size</TableCell>
										<TableCell>
											{blockData.BlockchainTxData.TransactionType === 'validator' ? '1' : 2141}{' '}
										</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Transaction hash</TableCell>
										<TableCell>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Box>{truncateEthAddress(blockData.BlockchainTxData.TxHash)}</Box>
												<Copy value={blockData.BlockchainTxData.TxHash} />
											</Box>
										</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Blockchain submit Time</TableCell>
										<TableCell>
											{blockData.BlockchainTxData.CreatedAt !== '0001-01-01T00:00:00Z'
												? new Date(blockData.BlockchainTxData.CreatedAt).toLocaleString()
												: '-'}
										</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Gas</TableCell>
										<TableCell>{blockData.BlockchainTxData.Gas}</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Nonce</TableCell>
										<TableCell>{blockData.BlockchainTxData.Nonce}</TableCell>
									</StyledTableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12} md={6}>
						<BlockchainStates />
					</Grid>
				</Grid>
			</Box>
			<BlockchainTransations />
		</>
	);
};
