export const truncateEthAddress = (address, left = 10, right = 6) => {
	if (!address) return '';
	const truncateRegex = new RegExp(`^(0x[a-zA-Z0-9]{${left}})[a-zA-Z0-9]+([a-zA-Z0-9]{${right}})$`);
	const match = address.match(truncateRegex);
	if (!match) return address;
	return `${match[1]}…${match[2]}`;
};

export const findPrecision = (entry, decimal) => {
	let temp = entry.toString();
	if (temp.includes('.')) {
		let input = temp.split('.');
		let fraction = input[1].substring(0, decimal);
		let res = input[0] + '.' + fraction;
		return res;
	} else return temp;
};

export const avoidNotation = (numIn) => {
	if (!numIn) return '';
	if (numIn === 'NaN' || numIn === 'undefined') return numIn;
	numIn += ''; // To cater to numric entries
	let sign = ''; // To remember the number sign
	// remove - sign & remember it
	if (numIn.charAt(0) === '-') {
		sign = '-';
		numIn = numIn.slice(1);
	}
	let str = numIn.split(/[eE]/g); // Split numberic string at e or E
	if (str.length < 2) return sign + numIn; // Not an Exponent Number? Exit with orginal Num back
	const power = Number(str[1]); // Get Exponent (Power) (could be + or -)

	const deciSp = (1.1).toLocaleString().substring(1, 2); // Get Deciaml Separator
	str = str[0].split(deciSp); // Split the Base Number into LH and RH at the decimal point
	let baseRH = str[1] || '', // RH Base part. Make sure we have a RH fraction else ""
		baseLH = str[0]; // LH base part.

	if (power >= 0) {
		// ------- Positive Exponents (Process the RH Base Part)
		if (power > baseRH.length) baseRH += '0'.repeat(power - baseRH.length); // Pad with "0" at RH
		baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power); // Insert decSep at the correct place into RH base
		if (baseRH.charAt(baseRH.length - 1) === deciSp) baseRH = baseRH.slice(0, -1); // If decSep at RH end? => remove it
	} else {
		// ------- Negative exponents (Process the LH Base Part)
		const num = Math.abs(power) - baseLH.length; // Delta necessary 0's
		if (num > 0) baseLH = '0'.repeat(num) + baseLH; // Pad with "0" at LH
		baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power); // Insert "." at the correct place into LH base
		if (baseLH.charAt(0) === deciSp) baseLH = '0' + baseLH; // If decSep at LH most? => add "0"
	}
	// Rremove leading and trailing 0's and Return the long number (with sign)
	return sign + (baseLH + baseRH).replace(/^0*(\d+|\d+\.\d+?)\.?0*$/, '$1');
};
export const calculateDecimal = (entry, decimal) => {
	let res = entry / Math.pow(10, decimal);
	return res;
};

export const calculateAmount = (amount, currencyid, currencies) => {
	let result1 = calculateDecimal(amount, currencies[currencyid]?.Decimal);
	let result = findPrecision(result1, 6);
	return result;
};

export const gasPriceInWei = (gaspriceInWei, gasused, nativeUsd) => {
	let gprice = gaspriceInWei;
	let val = gprice * gasused;
	let res = (val / Math.pow(10, 18)) * nativeUsd;
	let res1 = findPrecision(res, 2);
	if (isNaN(res1)) {
		return '-';
	}
	return findPrecision(res, 2);
};

export const type = (type) => {
	switch (type) {
		case 'settlement':
			return 'Settlement';
		case 'validatorRegistration':
			return 'Validator Registration';
		default:
			return 'Unknown';
	}
};

export const antiType = (type) => {
	switch (type) {
		case 'Settlement':
			return 'settlement';
		case 'Validator Registration':
			return 'validatorRegistration';
		default:
			return 'Unknown';
	}
};
