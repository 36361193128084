import React, { useState, useEffect } from 'react';
import { Loader } from '../Loader';
import { Paper, Grid, Typography, Box } from '@mui/material';
import { statusApi } from '../../Api/apicalls';

export const Blockdata = () => {
	const [commited, setcommited] = useState(0);
	const [total, settotal] = useState(0);
	const [loading, setloading] = useState(false);
	async function callapi() {
		try {
			setloading(true);
			let result = await statusApi();
			setcommited(result.data.message.WorkflowsCount);
			settotal(result.data.message.TransactionsCount);
			setloading(false);
		} catch (e) {
			setloading(false);
			console.log('status api error', e);
		}
	}

	useEffect(() => {
		callapi();
		return () => {};
	}, []);

	if (loading) {
		return <Loader />;
	}
	return (
		<Box>
			<Typography sx={{ fontSize: '32px', fontWeight: 'bold', color: '#1a1a1a' }}>Nume Block Explorer</Typography>
			<Typography sx={{ fontSize: '20px', color: '#1a1a1a', mb: 4 }}>
				View real-time data on blocks, transactions, accounts, and other on-chain activity.
			</Typography>
			<Paper elevation={2} sx={{ mb: 4 }}>
				<Grid container sx={{ p: 2 }}>
					<Grid item xs={12} md={8} sx={{ pl: 2 }}>
						<Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Network stats</Typography>
						{/* <Typography sx={{ fontSize: '16px', color: '#484848' }}>
							Stats are occasionally reset on testnet.
						</Typography> */}
					</Grid>
					<Grid item xs={12} md={2} sx={{ borderRight: { xs: 'none', md: '1px solid #e8e8e8' }, pl: 2 }}>
						<Typography sx={{ fontSize: '18px', color: '#484848' }}>Transactions</Typography>
						<Typography sx={{ fontSize: '30px', fontWeight: 'bold' }}>{total}</Typography>
					</Grid>
					<Grid item xs={12} md={2} sx={{ pl: 2 }}>
						<Typography sx={{ fontSize: '18px', color: '#484848' }}>Commited blocks</Typography>
						<Typography sx={{ fontSize: '30px', fontWeight: 'bold' }}>{commited}</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
