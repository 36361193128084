import {
	Box,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TimeAgo from 'javascript-time-ago';
import { CurrencyLogo } from '../../Components/CurrencyLogo';
import { Copy } from '../../Components/Copy';
import { truncateEthAddress, calculateAmount } from '../../utils';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#f9fafb',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const NoData = () => {
	return (
		<Box sx={{ mt: 4, textAlign: 'center' }}>
			<Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>No data</Typography>
		</Box>
	);
};
export const SettlementData = ({ currencies, blockData }) => {
	console.log(
		blockData.WorkflowData.Deposits.length +
			blockData.WorkflowData.Withdrawals.length +
			blockData.WorkflowData.Transfers.length
	);
	if (
		blockData.WorkflowData.Deposits.length +
			blockData.WorkflowData.Withdrawals.length +
			blockData.WorkflowData.Transfers.length +
			blockData.WorkflowData.NftDeposits +
			blockData.WorkflowData.NftTransfers +
			blockData.WorkflowData.NftMints +
			blockData.WorkflowData.NftWithdrawals ===
		0
	) {
		return <NoData />;
	}
	const DepositData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 2 }}>Deposits</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Token</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.Deposits.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.SenderAddress)} </Box>{' '}
											<Copy size="18px" value={row.SenderAddress} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.User.Address)}</Box>{' '}
											<Copy size="18px" value={row.User.Address} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>
											<CurrencyLogo
												token={currencies[row.CurrencyId]?.Abbreviation}
												style={{
													verticalAlign: 'middle',
													marginRight: '8px',
													height: '24px',
													width: '24px',
												}}
											/>
											{currencies[row.CurrencyId]?.Abbreviation}{' '}
										</Box>
									</TableCell>
									<TableCell>
										{calculateAmount(row.Amount, row.CurrencyId, currencies)}{' '}
										{currencies[row.CurrencyId]?.Abbreviation}
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const NftDepositData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 2 }}>NFT Deposits</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Contract address</TableCell>
								<TableCell>Token ID</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.NftDeposits.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.SenderAddress)} </Box>{' '}
											<Copy size="18px" value={row.SenderAddress} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.User.Address)}</Box>{' '}
											<Copy size="18px" value={row.User.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.Nft.ContractAddress)}</Box>{' '}
											<Copy size="18px" value={row.Nft.ContractAddress} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>{row.Nft.TokenId}</Box>
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const TransferData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>Transfers</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Token</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.Transfers.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.FromUser.Address)} </Box>{' '}
											<Copy size="18px" value={row.FromUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.ToUser.Address)}</Box>{' '}
											<Copy size="18px" value={row.ToUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>
											<CurrencyLogo
												token={currencies[row.CurrencyID]?.Abbreviation}
												style={{
													verticalAlign: 'middle',
													marginRight: '8px',
													height: '24px',
													width: '24px',
												}}
											/>
											{currencies[row.CurrencyID]?.Abbreviation}{' '}
										</Box>
									</TableCell>
									<TableCell>
										{calculateAmount(row.Amount, row.CurrencyID, currencies)}{' '}
										{currencies[row.CurrencyID]?.Abbreviation}
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const NftTransferData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>NFT Transfers</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Contract address</TableCell>
								<TableCell>Token ID</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.NftTransfers.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.FromUser.Address)} </Box>{' '}
											<Copy size="18px" value={row.FromUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.ToUser.Address)}</Box>{' '}
											<Copy size="18px" value={row.ToUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.Nft.ContractAddress)}</Box>{' '}
											<Copy size="18px" value={row.Nft.ContractAddress} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>{row.Nft.TokenId}</Box>
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const NftMintData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>NFT Mint</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Minter</TableCell>
								<TableCell>Nft contract address</TableCell>
								<TableCell>Token ID</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.NftMints.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.User.Address)} </Box>{' '}
											<Copy size="18px" value={row.User.Address} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.Nft.ContractAddress)} </Box>{' '}
											<Copy size="18px" value={row.Nft.ContractAddress} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>{row.Nft.TokenId}</Box>
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const WithdrawalData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>Withdrawals</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Token</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.Withdrawals.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.FromUser.Address)} </Box>{' '}
											<Copy size="18px" value={row.FromUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.To)}</Box> <Copy size="18px" value={row.To} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>
											<CurrencyLogo
												token={currencies[row.CurrencyID]?.Abbreviation}
												style={{
													verticalAlign: 'middle',
													marginRight: '8px',
													height: '24px',
													width: '24px',
												}}
											/>
											{currencies[row.CurrencyID]?.Abbreviation}{' '}
										</Box>
									</TableCell>
									<TableCell>
										{calculateAmount(row.Amount, row.CurrencyID, currencies)}{' '}
										{currencies[row.CurrencyID]?.Abbreviation}
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const NftWithdrawalData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>NFT Withdrawals</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Contract address</TableCell>
								<TableCell>Token ID</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.NftWithdrawals.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.FromUser.Address)} </Box>{' '}
											<Copy size="18px" value={row.FromUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.To)}</Box> <Copy size="18px" value={row.To} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.Nft.ContractAddress)} </Box>{' '}
											<Copy size="18px" value={row.Nft.ContractAddress} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>{row.Nft.TokenId}</Box>
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const ContractWithdrawalData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>Trustless withdrawal</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Token</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.ContractWithdrawals.map((row, index) => {
								return (
									<StyledTableRow hover key={index}>
										<TableCell>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Box>{truncateEthAddress(row.User.Address)} </Box>{' '}
												<Copy size="18px" value={row.User.Address} />
											</Box>
										</TableCell>
										<TableCell>
											{' '}
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Box>{truncateEthAddress(row.User.Address)}</Box>{' '}
												<Copy size="18px" value={row.User.Address} />
											</Box>
										</TableCell>
										<TableCell>
											<Box sx={{ display: 'flex' }}>
												<CurrencyLogo
													token={currencies[row.CurrencyId]?.Abbreviation}
													style={{
														verticalAlign: 'middle',
														marginRight: '8px',
														height: '24px',
														width: '24px',
													}}
												/>
												{currencies[row.CurrencyId]?.Abbreviation}{' '}
											</Box>
										</TableCell>
										<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
									</StyledTableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const NftTradeData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>NFT Trades</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>NFT contract address</TableCell>
								<TableCell>NFT token ID</TableCell>
								<TableCell>Currency</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.NftTrades.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.FromUser.Address)} </Box>{' '}
											<Copy size="18px" value={row.FromUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.ToUser.Address)}</Box>{' '}
											<Copy size="18px" value={row.ToUser.Address} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.Nft.ContractAddress)} </Box>{' '}
											<Copy size="18px" value={row.Nft.ContractAddress} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>{row.Nft.TokenId}</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>
											<CurrencyLogo
												token={currencies[row.CurrencyId]?.Abbreviation}
												style={{
													verticalAlign: 'middle',
													marginRight: '8px',
													height: '24px',
													width: '24px',
												}}
											/>
											{currencies[row.CurrencyId]?.Abbreviation}{' '}
										</Box>
									</TableCell>
									<TableCell>
										{calculateAmount(row.BuyAmount, row.CurrencyId, currencies)}{' '}
										{currencies[row.CurrencyId]?.Abbreviation}
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const NftContractWithdrawalData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>NFT Contract Withdrawals</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell>To</TableCell>
								<TableCell>Contract address</TableCell>
								<TableCell>Token ID</TableCell>
								<TableCell>Age</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.NftContractWithdrawals.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.User.Address)} </Box>{' '}
											<Copy size="18px" value={row.User.Address} />
										</Box>
									</TableCell>
									<TableCell>
										{' '}
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.User.Address)}</Box>{' '}
											<Copy size="18px" value={row.User.Address} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.Nft.ContractAddress)} </Box>{' '}
											<Copy size="18px" value={row.Nft.ContractAddress} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex' }}>{row.Nft.TokenId}</Box>
									</TableCell>
									<TableCell>{timeAgo.format(new Date(row.CreatedAt))}</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	const SubscriptionData = () => {
		return (
			<>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold', mt: 4 }}>Subscriptions</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>User</TableCell>
								<TableCell>Type</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{blockData.WorkflowData.Subscriptions.map((row, index) => (
								<StyledTableRow hover key={index}>
									<TableCell>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Box>{truncateEthAddress(row.User)} </Box>{' '}
											<Copy size="18px" value={row.User} />
										</Box>
									</TableCell>
									<TableCell>
										<Box sx={{ display: 'flex', textTransform: 'uppercase' }}>{row.Type}</Box>
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	};

	return (
		<Box sx={{ mt: 4 }}>
			<Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>Block Transactions</Typography>
			{blockData.WorkflowData.Deposits.length > 0 && <DepositData />}
			{blockData.WorkflowData.Transfers.length > 0 && <TransferData />}
			{blockData.WorkflowData.Withdrawals.length > 0 && <WithdrawalData />}
			{blockData.WorkflowData.ContractWithdrawals.length > 0 && <ContractWithdrawalData />}
			{blockData.WorkflowData.NftDeposits.length > 0 && <NftDepositData />}
			{blockData.WorkflowData.NftMints.length > 0 && <NftMintData />}
			{blockData.WorkflowData.NftTransfers.length > 0 && <NftTransferData />}
			{blockData.WorkflowData.NftWithdrawals.length > 0 && <NftWithdrawalData />}
			{blockData.WorkflowData.NftTrades.length > 0 && <NftTradeData />}
			{blockData.WorkflowData.NftContractWithdrawals.length > 0 && <NftContractWithdrawalData />}
			{blockData.WorkflowData.Subscriptions.length > 0 && <SubscriptionData />}
		</Box>
	);
};

export const SettlementState = ({ blockData }) => {
	return (
		<TableContainer component={Paper} sx={{ mt: 1 }}>
			<Table>
				<TableBody>
					<StyledTableRow hover>
						<TableCell variant="head">Settlement Root</TableCell>
						<TableCell>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box>{truncateEthAddress(blockData.BlockchainTxData.logs.Root)}</Box>
								<Copy value={blockData.BlockchainTxData.logs.Root} />
							</Box>
						</TableCell>
					</StyledTableRow>
					<StyledTableRow hover>
						<TableCell variant="head">Settlement number</TableCell>
						<TableCell>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box>{blockData.BlockchainTxData.logs.BlockNumber}</Box>
								<Copy value={blockData.BlockchainTxData.logs.BlockNumber} />
							</Box>
						</TableCell>
					</StyledTableRow>
					{blockData.BlockchainTxData.logs.HandledDepositsQueueIndex && (
						<StyledTableRow hover>
							<TableCell variant="head">Handled Deposits Queue Index</TableCell>
							<TableCell>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box>{blockData.BlockchainTxData.logs.HandledDepositsQueueIndex}</Box>
									<Copy value={blockData.BlockchainTxData.logs.HandledDepositsQueueIndex} />
								</Box>
							</TableCell>
						</StyledTableRow>
					)}
					{blockData.BlockchainTxData.logs.WithdrawalsHash && (
						<StyledTableRow hover>
							<TableCell variant="head">Withdrawal Hash</TableCell>
							<TableCell>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box>{truncateEthAddress(blockData.BlockchainTxData.logs.WithdrawalsHash)}</Box>
									<Copy value={blockData.BlockchainTxData.logs.WithdrawalsHash} />
								</Box>
							</TableCell>
						</StyledTableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
