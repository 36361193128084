import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Main } from './pages/Main';
import { Selectedblocks } from './pages/SelectedBlocks';
import { PendingSelectedBlocks } from './pages/SelectedBlocks';
import { TransactionData } from './pages/Transaction';
import { theme } from './theme';
import { Navbar } from './Components/Navbar';
import { Charts } from './pages/Chart/';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route
							path="/"
							exact
							element={
								<Navbar>
									<Main />
								</Navbar>
							}
						/>
						<Route
							path="/block/:blockid"
							exact
							element={
								<Navbar>
									<Selectedblocks />
								</Navbar>
							}
						/>
						<Route
							path="/tx/:txHash"
							exact
							element={
								<Navbar>
									<TransactionData />
								</Navbar>
							}
						/>
						<Route
							path="/pending-block/:workflowType/:settlementId"
							exact
							element={
								<Navbar>
									<PendingSelectedBlocks />
								</Navbar>
							}
						/>
						<Route
							path="/pending-block/:workflowType"
							exact
							element={
								<Navbar>
									<PendingSelectedBlocks />
								</Navbar>
							}
						/>
						<Route
							path="/charts"
							exact
							element={
								<Navbar>
									<Charts />
								</Navbar>
							}
						/>
					</Routes>
				</BrowserRouter>
			</div>
		</ThemeProvider>
	);
}

export default App;
