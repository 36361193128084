import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getTxDetails, getCurrencies } from '../../Api/apicalls';
import {
	Box,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Button,
	Grid,
} from '@mui/material';
import { Loader } from '../../Components/Loader';
import { styled } from '@mui/material/styles';
import { Copy } from '../../Components/Copy';
import { truncateEthAddress, calculateAmount } from '../../utils';
import { CurrencyLogo } from '../../Components/CurrencyLogo';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#f9fafb',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const TransactionData = () => {
	const [txData, setTxData] = useState();
	const [currencies, setCurrencies] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const { txHash } = useParams();

	async function fetchTx(txHash) {
		try {
			let res = await getTxDetails(txHash);
			setTxData(res.data.message);
			let res1 = await getCurrencies();
			let d = {};
			res1.data.message.Currencies.forEach((e) => {
				d[e.ID] = e;
			});
			setCurrencies(d);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchTx(txHash);
		return () => {};
	}, [txHash]);

	if (loading) {
		return <Loader />;
	}
	const date = new Date(txData.CreatedAt);
	return (
		<>
			<Box>
				<Button onClick={() => navigate('/')} variant="outlined" sx={{ textTransform: 'none' }}>
					Dashboard
				</Button>
				<Typography sx={{ fontSize: '36px', fontWeight: 'bold', mt: 1 }}>Transaction Details</Typography>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					<Grid item xs={12} md={12}>
						<TableContainer component={Paper} sx={{ mt: 1 }}>
							<Table>
								<TableBody>
									<StyledTableRow hover>
										<TableCell variant="head">Type</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>{txData.Type}</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Transaction Hash</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Box>{truncateEthAddress(txData.Hash)}</Box>
												<Copy value={txData.Hash} />{' '}
											</Box>
										</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Status</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>
											<Box sx={{ maxWidth: '100px', textAlign: 'center' }}>
												{txData.Status === 'PENDING1' ? (
													<Box
														sx={{
															background: '#ffdfa3',
															color: '#f7a102',
															p: 0.5,
															fontSize: '14px',
															borderRadius: '8px',
															textAlign: 'center',
														}}>
														SUCCESS
														{/* {txData.Status} */}
													</Box>
												) : (
													<Box
														sx={{
															background: '#bbf7d0',
															color: '#16a34a',
															p: 0.5,
															fontSize: '14px',
															borderRadius: '8px',
														}}>
														SUCCESS
														{/* {txData.Status}{' '} */}
													</Box>
												)}
											</Box>
										</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">From</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Box>{truncateEthAddress(txData.From)}</Box>
												<Copy value={txData.From} />{' '}
											</Box>
										</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">To</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Box>{truncateEthAddress(txData.To)}</Box>
												<Copy value={txData.To} />{' '}
											</Box>
										</TableCell>
									</StyledTableRow>
									{txData.Type.includes('nft') ? (
										<>
											<StyledTableRow hover>
												<TableCell variant="head">NFT contract address</TableCell>
												<TableCell sx={{ textTransform: 'capitalize' }}>
													<Box sx={{ display: 'flex', alignItems: 'center' }}>
														<Box>{truncateEthAddress(txData.CurrencyOrNftContract)}</Box>
														<Copy value={txData.CurrencyOrNftContract} />
													</Box>
												</TableCell>
											</StyledTableRow>
											<StyledTableRow hover>
												<TableCell variant="head">NFT token ID</TableCell>
												<TableCell sx={{ textTransform: 'capitalize' }}>
													{txData.AmountOrNftTokenId}
												</TableCell>
											</StyledTableRow>
										</>
									) : (
										<>
											<StyledTableRow hover>
												<TableCell variant="head">Currency</TableCell>
												<TableCell sx={{ textTransform: 'capitalize' }}>
													<Box sx={{ display: 'flex', alignItems: 'center' }}>
														<CurrencyLogo
															token={currencies[txData.CurrencyIdOrNftId]?.Abbreviation}
															style={{
																verticalAlign: 'middle',
																marginRight: '8px',
																height: '24px',
																width: '24px',
															}}
														/>
														<Box>{truncateEthAddress(txData.CurrencyOrNftContract)}</Box>
														<Copy value={txData.CurrencyOrNftContract} />
													</Box>
												</TableCell>
											</StyledTableRow>
											<StyledTableRow hover>
												<TableCell variant="head">Amount</TableCell>
												<TableCell sx={{ textTransform: 'capitalize' }}>
													{calculateAmount(
														txData.AmountOrNftTokenId,
														txData.CurrencyIdOrNftId,
														currencies
													)}{' '}
													{currencies[txData.CurrencyIdOrNftId]?.Abbreviation}{' '}
												</TableCell>
											</StyledTableRow>
										</>
									)}
									<StyledTableRow hover>
										<TableCell variant="head">Nonce</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>{txData.Nonce}</TableCell>
									</StyledTableRow>
									<StyledTableRow hover>
										<TableCell variant="head">Timestamp</TableCell>
										<TableCell sx={{ textTransform: 'capitalize' }}>
											{date.toLocaleString()}
										</TableCell>
									</StyledTableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};
