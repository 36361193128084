import React from 'react';
import { ReactComponent as EthSvg } from '../../assets/images/token-icon/eth.svg';
import { ReactComponent as DaiSvg } from '../../assets/images/token-icon/dai.svg';
import { ReactComponent as WbtcSvg } from '../../assets/images/token-icon/wbtc.svg';
import { ReactComponent as UsdtSvg } from '../../assets/images/token-icon/usdt.svg';
import { ReactComponent as UsdcSvg } from '../../assets/images/token-icon/usdc.svg';
import { ReactComponent as MaticSvg } from '../../assets/images/token-icon/matic.svg';

export const CurrencyLogo = ({ token, style }) => {
	switch (token) {
		case 'ETH':
			return <EthSvg style={style} />;
		case 'DAI':
			return <DaiSvg style={style} />;
		case 'WBTC':
			return <WbtcSvg style={style} />;
		case 'USDT':
			return <UsdtSvg style={style} />;
		case 'USDC':
			return <UsdcSvg style={style} />;
		case 'MATIC':
			return <MaticSvg style={style} />;
		default:
			return null;
	}
};
