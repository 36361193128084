import axios from 'axios';

const axiosConfig = {
	headers: {},
};

export const statusApi = () => {
	const uri = process.env.REACT_APP_NUME_BACKEND_URL + 'explorer/get-network-data';
	return axios.get(uri, axiosConfig);
};

export const metricsApi = (apiKey) => {
	const uri = process.env.REACT_APP_NUME_BACKEND_URL + 'private/product-metrics';
	const headers = {
		'NI-API-KEY': apiKey,
	};
	return axios.get(uri, { headers });
};

export const getWorkflows = (limit, offset) => {
	const uri = process.env.REACT_APP_NUME_BACKEND_URL + `explorer/get-workflows?limit=${limit}&offset=${offset}`;
	return axios.get(uri, axiosConfig);
};

export const getCurrencies = () => {
	const url = process.env.REACT_APP_NUME_BACKEND_URL + 'get-currencies';
	return axios.get(url, axiosConfig);
};

export const getPendingWorkflows = () => {
	const uri = process.env.REACT_APP_NUME_BACKEND_URL + `explorer/get-pending-workflows`;
	return axios.get(uri, axiosConfig);
};

export const getWorkflowDetails = (blockchainid) => {
	const url = process.env.REACT_APP_NUME_BACKEND_URL + `explorer/get-workflow-details?id=${blockchainid}`;
	return axios.get(url, axiosConfig);
};

export const getTxDetails = (blockchainid) => {
	const url = process.env.REACT_APP_NUME_BACKEND_URL + `transaction/${blockchainid}`;
	return axios.get(url, axiosConfig);
};

export const getPendingWorkflowDetails = (type, settlementId) => {
	const url =
		process.env.REACT_APP_NUME_BACKEND_URL +
		`explorer/get-pending-workflow-details?type=${type}&settlementId=${settlementId}`;
	return axios.get(url, axiosConfig);
};
