import React from 'react';
import { Blockdata } from '../../Components/Blockdata';
import { LatestBlocks } from '../../Components/LatestBlocks';

export const Main = () => {
	return (
		<>
			<Blockdata />
			<LatestBlocks />
		</>
	);
};
