import React, { useEffect, useState } from 'react';
import { statusApi, getWorkflows, getPendingWorkflows } from '../../Api/apicalls';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import en from 'javascript-time-ago/locale/en';
import { Copy } from '../Copy';
import { antiType } from '../../utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#f9fafb',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

export const LatestBlocks = () => {
	let limit = 10;
	const [page, setPage] = useState(1);
	const [apidata, setapidata] = useState([]);
	const [pendingdata, setpendingdata] = useState([]);
	const [txCount, setTxCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const pageChange = async (event, val) => {
		let offset = (val - 1) * limit;
		if (val > 1) limit = 10;
		else limit -= 3;
		if (val > 1) offset -= 3;

		setPage(val);
		setIsLoading(true);
		const result = await getWorkflows(limit, offset);
		setIsLoading(false);
		setapidata(result.data.message.Workflows);
	};

	function handleselectedblock(param) {
		navigate(`/block/${param.Block}`);
	}

	function handlePendingSelectedBlock(param) {
		navigate(`/pending-block/${antiType(param.TransactionType)}/${param.SettlementId}`);
	}

	async function callapi() {
		try {
			setIsLoading(true);
			const response = await statusApi();
			setTxCount(response.data.message.WorkflowsCount);
			const result = await getWorkflows(limit - 3, 0);
			setapidata(result.data.message.Workflows);
			const res = await getPendingWorkflows();
			let pen = [];
			console.log(res.data.message, 'LPKIJSAklfjasklfja');
			res.data.message.forEach((item) => {
				item.PendingItems.forEach((ele) => {
					if (ele.TransactionType === 'Settlement') {
						pen.push(ele);
					}
				});
			});
			setpendingdata(pen);
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.log('call api method from latest block falining', e);
		}
	}

	useEffect(() => {
		callapi();
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	console.log(isLoading);
	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Block</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Transaction hash</TableCell>
							<TableCell>Gas</TableCell>
							<TableCell style={{ minWidth: '120px' }}>Blockchain submit time</TableCell>
							<TableCell>Size</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<>
								{[...Array(10)].map((item, index) => {
									return (
										<StyledTableRow hover key={index}>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
										</StyledTableRow>
									);
								})}
							</>
						) : (
							<>
								{page === 1 &&
									pendingdata.map((item, index) => {
										let status =
											item.Status.charAt(0).toUpperCase() + item.Status.slice(1).toLowerCase();
										return (
											<StyledTableRow hover key={index}>
												<TableCell
													onClick={() => {
														handlePendingSelectedBlock(item);
													}}
													sx={{ cursor: 'pointer', color: '#0066C0' }}>
													<u>{'-'}</u>
												</TableCell>
												<TableCell>{item.TransactionType}</TableCell>
												<TableCell>
													<Box>
														{item.Status === 'PENDING' ? (
															<Box
																sx={{
																	background: '#ffdfa3',
																	color: '#f7a102',
																	p: 0.5,
																	fontSize: '14px',
																	borderRadius: '8px',
																	textAlign: 'center',
																}}>
																{status}
															</Box>
														) : (
															<Box
																sx={{
																	background: '#bbf7d0',
																	color: '#16a34a',
																	p: 0.5,
																	fontSize: '14px',
																	borderRadius: '8px',
																}}>
																{status}{' '}
															</Box>
														)}
													</Box>
												</TableCell>
												<TableCell>{'-'}</TableCell>
												<TableCell>{'-'}</TableCell>
												<TableCell style={{ minWidth: '120px' }}>{'-'}</TableCell>
												<TableCell> {item.ItemCount}</TableCell>
											</StyledTableRow>
										);
									})}
								{apidata.map((item, index) => {
									console.log(item);
									let status =
										item.Status.charAt(0).toUpperCase() + item.Status.slice(1).toLowerCase();
									return (
										<StyledTableRow hover key={index}>
											<TableCell
												onClick={() => {
													handleselectedblock(item);
												}}
												sx={{ cursor: 'pointer', color: '#0066C0' }}>
												<u>{item.Block}</u>
											</TableCell>
											<TableCell>{item.TransactionType}</TableCell>
											<TableCell>
												<Box>
													{item.Status === 'FAILED' ? (
														<Box
															sx={{
																background: '#fa8c84',
																color: '#f20c0c',
																p: 0.5,
																fontSize: '14px',
																borderRadius: '8px',
																textAlign: 'center',
															}}>
															{status}
														</Box>
													) : item.Status === 'PENDING' ? (
														<Box
															sx={{
																background: '#ffdfa3',
																color: '#f7a102',
																p: 0.5,
																fontSize: '14px',
																borderRadius: '8px',
																textAlign: 'center',
															}}>
															{status}
														</Box>
													) : (
														<Box
															sx={{
																background: '#bbf7d0',
																color: '#16a34a',
																p: 0.5,
																fontSize: '14px',
																borderRadius: '8px',
															}}>
															{status}{' '}
														</Box>
													)}
												</Box>
											</TableCell>
											<TableCell>
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													<Box>{item.TxHash}</Box>
													<Copy size="20px" value={item.TxHash} />
												</Box>
											</TableCell>
											<TableCell>{item.Gas ? item.Gas : '-'}</TableCell>
											<TableCell style={{ minWidth: '120px' }}>
												{' '}
												{item.SubmittedToBlockchainAt !== '0001-01-01T00:00:00Z'
													? timeAgo.format(new Date(item.SubmittedToBlockchainAt))
													: '-'}{' '}
											</TableCell>
											<TableCell> {item.TransactionCount}</TableCell>
										</StyledTableRow>
									);
								})}
							</>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Box display="flex" justifyContent="center" sx={{ marginTop: '24px' }}>
				<Pagination
					sx={{ paddingTop: '20px', justifyContent: 'center' }}
					count={Math.ceil((txCount + 3) / limit)}
					variant="outlined"
					shape="rounded"
					color="primary"
					page={page}
					onChange={pageChange}
				/>
			</Box>
		</>
	);
};
