import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getPendingWorkflowDetails, getCurrencies } from '../../Api/apicalls';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button } from '@mui/material';
import { Loader } from '../../Components/Loader';
import { styled } from '@mui/material/styles';
import { SettlementData } from './Settlement';
import { ValidatorRegistrationData } from './ValidatorRegistration';
import { type } from '../../utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#f9fafb',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const PendingSelectedBlocks = () => {
	const [blockData, setBlockData] = useState();
	const [currencies, setCurrencies] = useState([]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const { workflowType, settlementId } = useParams();

	useEffect(() => {
		async function handleblocktransactions(workflowTypem, settlementId) {
			try {
				let res = await getPendingWorkflowDetails(workflowType, settlementId);
				setBlockData(res.data.message);
				let res1 = await getCurrencies();
				let d = {};
				res1.data.message.Currencies.forEach((e) => {
					d[e.ID] = e;
				});
				setCurrencies(d);
				setLoading(false);
			} catch (e) {
				setLoading(false);
			}
		}
		handleblocktransactions(workflowType, settlementId);
		return () => {};
	}, [workflowType, settlementId]);

	if (loading) {
		return <Loader />;
	}

	const BlockchainTransations = () => {
		switch (workflowType) {
			case 'settlement':
				return <SettlementData blockData={blockData} currencies={currencies} />;
			case 'userRegistration':
				return <ValidatorRegistrationData blockData={blockData} currencies={currencies} />;
			default:
				return <></>;
		}
	};

	return (
		<>
			<Box>
				<Button onClick={() => navigate('/')} variant="outlined" sx={{ textTransform: 'none' }}>
					Dashboard
				</Button>
				<Typography sx={{ fontSize: '36px', fontWeight: 'bold', mt: 1 }}>
					Workflow #{type(workflowType)}
				</Typography>
				<TableContainer component={Paper} sx={{ mt: 1 }}>
					<Table>
						<TableBody>
							<StyledTableRow hover>
								<TableCell variant="head">Size</TableCell>
								<TableCell>
									{blockData.WorkflowData.length
										? blockData.WorkflowData.length
										: workflowType === 'merchant_registration'
										? 1
										: '-'}{' '}
								</TableCell>
							</StyledTableRow>
							<StyledTableRow hover>
								<TableCell variant="head">Transaction hash</TableCell>
								<TableCell>{'-'}</TableCell>
							</StyledTableRow>
							<StyledTableRow hover>
								<TableCell variant="head">Blockchain submit Time</TableCell>
								<TableCell>{'-'}</TableCell>
							</StyledTableRow>
							<StyledTableRow hover>
								<TableCell variant="head">Gas</TableCell>
								<TableCell>{'-'}</TableCell>
							</StyledTableRow>
							<StyledTableRow hover>
								<TableCell variant="head">Nonce</TableCell>
								<TableCell>{'-'}</TableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
			<BlockchainTransations />
		</>
	);
};
