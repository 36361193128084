import { ReactComponent as CopySvg } from '../../assets/images/copy.svg';
import { useState } from 'react';
import { Popover, Box } from '@mui/material';

export const Copy = ({ value, size }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const copyToClipboard = (event) => {
		setAnchorEl(event.currentTarget);
		navigator.clipboard.writeText(value);
		setTimeout(() => {
			setAnchorEl(null);
		}, 600);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	return (
		<>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				sx={{ mt: 0.5 }}>
				<Box sx={{ p: 1, fontSize: '12px', background: '#484848', color: '#e8e8e8' }}>Copied!</Box>
			</Popover>
			<CopySvg
				style={{
					width: size ? size : '24px',
					height: size ? size : '24px',
					color: '#6B7281',
					cursor: 'pointer',
				}}
				onClick={copyToClipboard}
			/>
		</>
	);
};
