import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Copy } from '../../Components/Copy';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#f9fafb',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));
const NoData = () => {
	return (
		<Box sx={{ mt: 4, textAlign: 'center' }}>
			<Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>No data</Typography>
		</Box>
	);
};
export const ValidatorRegistrationData = ({ blockData }) => {
	if (Array.isArray(blockData.WorkflowData) && blockData.WorkflowData.length === 0) {
		return <NoData />;
	}
	return (
		<Box sx={{ mt: 4 }}>
			<Typography sx={{ fontSize: '36px', fontWeight: 'bold' }}>Validators registered</Typography>
			<TableContainer component={Paper} sx={{ mt: 1 }}>
				<Table>
					<TableBody>
						{blockData.WorkflowData.map((key, index) => (
							<StyledTableRow hover key={index}>
								<TableCell variant="head">
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Box>{key.Address} </Box>
										<Copy size="18px" value={key.Address} />
									</Box>
								</TableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};
