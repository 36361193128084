import * as React from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import NumeLogo from '../../assets/images/nume-logo.png';
export const Navbar = ({ children }) => {
	async function addMaticNetwork() {
		try {
			const result = await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						chainId: process.env.REACT_APP_CHAIN_ID,
						rpcUrls: [process.env.REACT_APP_RPC_URL],
						chainName: process.env.REACT_APP_CHAIN_NAME,
						nativeCurrency: {
							name: process.env.REACT_APP_NT_NAME,
							symbol: process.env.REACT_APP_NT_SYMBOL,
							decimals: parseInt(process.env.REACT_APP_NT_DECIMAL),
						},
						blockExplorerUrls: [process.env.REACT_APP_BC_URL],
					},
				],
			});
			console.log(result);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<Box sx={{ maxWidth: '1240px', width: '100%' }}>
			<AppBar
				position="static"
				sx={{ background: '#fff', borderBottom: '1px solid #e8e8e8', mb: 1 }}
				elevation={0}>
				<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<img src={NumeLogo} alt="logo" height="64" />
					<Button variant="outlined" onClick={addMaticNetwork} sx={{ textTransform: 'none' }}>
						Add Nume Network
					</Button>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 2, mt: 2 }}>{children}</Box>
		</Box>
	);
};
